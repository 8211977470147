<template>
    <!-- TODO Refactor this component -->
    <div>
        <nl-loader v-if="preloading" :show="true" />
        <div v-else>
            <b-loading v-model="isLoading" :is-full-page="true" />

            <div class="vue-redactors mt-3 mb-3">
                <div class="card">
                    <div
                        class="card-header card-bg-redactor text-white flex-column flex-md-row justify-content-between"
                        @click.prevent="setIsOpen"
                    >
                        <h2 class="py-2 d-flex align-items-center">
                            {{ $t('redactor.title-tab-redactor') }}
                            <b-tag class="ml-3" type="is-warning"> Total: {{ countRedactors.all }}</b-tag>
                        </h2>
                    </div>
                    <div v-show="isOpen" class="card-body">
                        <RedactorTabs :tab-counts="countRedactors" />
                    </div>
                </div>
            </div>

            <div class="vue-redactors mt-3 mb-3">
                <div class="card">
                    <div
                        class="card-header card-bg-redactor text-white flex-column flex-md-row justify-content-between"
                        @click.prevent="setIsOpenKpis"
                    >
                        <h2 class="py-2 d-flex align-items-center">
                            KPIs
                            <b-tag class="ml-3" type="is-warning">
                                Total commandes:
                                {{
                                    countKpis.entity_type.content +
                                    countKpis.entity_type.preorder +
                                    countKpis.entity_type.spot
                                }}
                            </b-tag>
                        </h2>
                    </div>
                    <div v-show="isOpenKpis" class="card-body">
                        <writing-kpis :kpi-counts="countKpis" :credit="credit" />
                    </div>
                </div>
            </div>

            <div class="vue-redactors mt-3 mb-3">
                <div class="card">
                    <div
                        class="card-header card-bg-redactor text-white flex-column flex-md-row justify-content-between"
                        @click.prevent="isOpenAffectation = !isOpenAffectation"
                    >
                        <h2 class="py-2 d-flex align-items-center">Affectation rédacteur</h2>
                    </div>
                    <div v-show="isOpenAffectation" class="card-body">
                        <section>
                            <b-field grouped>
                                <section>
                                    <b-field>
                                        <b-radio v-model="commmandsType" native-value="spots"> Spot </b-radio>
                                    </b-field>
                                    <b-field>
                                        <b-radio v-model="commmandsType" native-value="preorders"> Préco </b-radio>
                                    </b-field>
                                </section>

                                <b-field label="Commandes à affecter">
                                    <b-input type="textarea" rows="10" v-model="assignedCommands"></b-input>
                                </b-field>
                                <b-field label="Rédacteur">
                                    <b-select placeholder="Select a name" v-model="assignedRedactor">
                                        <option
                                            v-for="redactor in affectRedactorsByType"
                                            :value="redactor.id"
                                            :key="redactor.id"
                                        >
                                            {{ redactor.lastname }} {{ redactor.firstname }} ({{ redactor.email }})
                                        </option>
                                    </b-select>
                                    <p class="ml-2">
                                        <b-button type="is-primary" @click.prevent="doAssignCommands"
                                            >Affecter
                                        </b-button>
                                    </p>
                                </b-field>
                            </b-field>
                        </section>
                    </div>
                </div>
            </div>

            <section>
                <template>
                    <div class="box">
                        <writing-tabs-status :tab-counts="countWritings" />
                    </div>
                </template>
            </section>
        </div>
    </div>
</template>

<style>
    .card-bg-redactor {
        background: #3e454d;
    }
</style>

<script>
    import { mapActions, mapState } from 'vuex'
    import RedactorTabs from '@/components/Admin/Redactor/RedactorTabs.vue'
    import WritingTabsStatus from '@/components/Admin/Redactor/WritingTabsStatus.vue'
    import WritingKpis from '@/components/Admin/Redactor/WritingKpis.vue'
    import NlStorage from "@/services/nl.storage";

    export default {
        name: 'AdminRedactorDashboard',
        title: 'Redactor',
        components: {
            WritingKpis,
            WritingTabsStatus,
            RedactorTabs
        },
        data: function () {
            return {
                countRedactors: {},
                countWritings: {},
                countKpis: {},

                groups: null,
                showActiveRedactor: false,

                preloading: true,
                isLoading: false,
                credit: 0,

                isOpen: false,
                isOpenKpis: false,
                isOpenAffectation: false,
                user: this.$store.state.auth.user,
                affectRedactors: [],
                commmandsType: 'spots',
                assignedCommands: '',
                assignedRedactor: null
            }
        },
        created() {
            let count = this.getDashboardCount()
            let dashboard = this.getRedactorDashboard()
            let orders = this.getWritingDashboard(this.currentTab)

            this.axios.get('/admin/redactors').then(response => {
                this.affectRedactors = response.data
            })

            let groups = this.axios.get('/crud/groups?orderBy=name').then(response => {
                this.groups = response.data.groups
            })

            this.killDuplicateCredits().then(data => {
                this.credit = data.credit
            })

            Promise.all([count, dashboard, groups, orders]).then(([{ redactors, writings, kpis }]) => {
                this.countRedactors = redactors
                this.countWritings = writings
                this.countKpis = kpis

                this.$store.commit('redactors/saveWritingsConfig')
                let opened = NlStorage.getItem('redactors.admin.redactors-panel')
                let openedKpis = NlStorage.getItem('kpis.admin.redactors-panel')

                if (opened) {
                    this.isOpen = JSON.parse(opened)
                }
                if (openedKpis) {
                    this.isOpenKpis = JSON.parse(openedKpis)
                }

                this.preloading = false
            })

            this.initEvents()
        },
        computed: {
            ...mapState('redactors', {
                redactors: state => state.redactors,

                writings: state => state.writings,
                config: state => state.writingsConfig,

                wordsRemainingToWrite: state => state.wordsRemainingToWrite,
                maxWordsPerDay: state => state.maxWordsPerDay
            }),
            affectRedactorsByType() {
                return this.affectRedactors.filter(redactor => redactor[this.commmandsType])
            },
            dailyWordsCount() {
                return (
                    Math.round((parseInt(this.wordsRemainingToWrite) / parseInt(this.maxWordsPerDay)) * 100) / 100
                ).toFixed(1)
            },
            currentTab: {
                get() {
                    return this.$store.state.redactors.currentTab
                },
                set(value) {
                    this.$store.commit('redactors/setCurrentTab', value)
                }
            }
        },
        methods: {
            ...mapActions('redactors', [
                'getDashboardCount',
                'getRedactorDashboard',
                'getWritingDashboard',
                'getWritingByStatus',
                'killDuplicateCredits',
                'resetFilters',
                'getSpotRedactors',
                'assignCommands'
            ]),

            doAssignCommands() {
                this.assignCommands({
                    redactor: this.assignedRedactor,
                    commands: this.assignedCommands,
                    commandsType: this.commmandsType
                })
                    .then(() => {
                        this.forceUpdate = !this.forceUpdate
                        this.$store.dispatch('toast', {
                            type: 'success',
                            title: 'Les commandes ont bien été attribuées au rédacteur',
                            message: ''
                        })
                    })
                    .catch(e => {
                        console.log(e)
                        this.$store.dispatch('toastError', e)
                    })
                    .finally(() => {
                        this.assignedRedactor = null
                        this.assignedCommands = []
                    })
            },
            onTabChange(status) {
                this.isLoading = true
                this.resetFilters()
                this.getWritingDashboard(status)
                    .then(() => {})
                    .catch(e => {
                        this.$store.dispatch('toast', {
                            type: 'error',
                            title: 'Une erreur est survenue',
                            message: e.response.data.message
                        })
                    })
                    .finally(() => {
                        this.isLoading = false
                    })
            },

            setIsOpen() {
                this.isOpen = !this.isOpen

                NlStorage.setItem('redactors.admin.redactors-panel', JSON.stringify(this.isOpen))
            },

            setIsOpenKpis() {
                this.isOpenKpis = !this.isOpenKpis

                NlStorage.setItem('kpis.admin.redactors-panel', JSON.stringify(this.isOpenKpis))
            },

            getWritingById(id) {
                let index = this.writings.data.findIndex(writing => writing.id === id)

                if (index >= 0) {
                    return {
                        index: index,
                        writing: this.writings.data[index]
                    }
                }

                return null
            },
            removeWriting(writingId) {
                let writing = this.getWritingById(writingId)
                if (writing && writing.index >= 0) {
                    this.writings.data.splice(writing.index, 1)
                }
            },

            addWriting(writingType, writingId) {
                this.config.filters = { id: writingId }

                this.getWritingByStatus(writingType).then(data => {
                    this.writings.data.push(data.writings.data[0])
                    this.config.filters = null
                })
            },

            initEvents() {
                this.$echo
                    .private(`writings`)
                    .listen('.WritingAssigned', event => {
                        if (event.userId === this.user.id) return
                        let { index, writing } = this.getWritingById(event.writingId)

                        writing.redactor = event.redactor
                        writing.redactor_id = event.redactor.id

                        this.writings.data.splice(index, 1, writing)
                    })
                    .listen('.WritingUnassigned', event => {
                        if (event.userId === this.user.id) return
                        let { index, writing } = this.getWritingById(event.writingId)

                        writing.redactor = null
                        writing.redactor_id = null

                        this.writings.data.splice(index, 1, writing)
                    })
                    .listen('.WritingIsPrio', event => {
                        if (event.userId === this.user.id) return
                        let { index, writing } = this.getWritingById(event.writingId)

                        writing.isPrio = event.isPrio
                        this.writings.data.splice(index, 1, writing)
                    })
                    .listen('.WritingInReview', event => {
                        if (event.userId === this.user.id) return

                        this.removeWriting(event.writingId)

                        if (this.currentTab === 'review') {
                            this.addWriting('review', event.writingId)
                        }
                    })
                    .listen('.WritingPublished', event => {
                        if (event.userId === this.user.id) return

                        this.removeWriting(event.writingId)

                        if (this.currentTab === 'done') {
                            this.addWriting('done', event.writingId)
                        }
                    })
                    .listen('.WritingCanceled', event => {
                        if (event.userId === this.user.id) return

                        this.removeWriting(event.writingId)
                    })
                    .listen('.WritingTodo', event => {
                        if (event.userId === this.user.id) return

                        this.removeWriting(event.writingId)
                        this.addWriting('processing', event.writingId)
                    })
                    .listen('.WritingForceReview', event => {
                        if (event.userId === this.user.id) return
                        let { index, writing } = this.getWritingById(event.writingId)

                        writing.force_review = event.hasReviewForced

                        this.writings.data.splice(index, 1, writing)
                    })
                    .listen('.WritingPending', event => {
                        if (event.userId === this.user.id) return
                        let { index, writing } = this.getWritingById(event.writingId)

                        writing.writing_status_id = event.status
                        writing.reason = event.reason

                        this.writings.data.splice(index, 1, writing)
                    })
            }
        }
    }
</script>

<style scoped>
    .vue-redactors .card-header:hover {
        cursor: pointer;
    }
</style>